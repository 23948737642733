<template>
  <b-container class="mt-2 container-max-width">
    <masthead title="Forms Library" :show-search="false">
      <router-link :to="{ name: 'dashpanel' }" class="router-link nav-link">
        <fa-icon icon="chevron-left" class="fa-xs" />
        Home
      </router-link>
    </masthead>
    <b-row>
      <b-col>
        <p class="mb-4">
          The Forms Library is a collection of resources to allow you to complete and submit a filing on your own.
          Depending on which filing methods your state offers, these may include forms to fill out and submit or links to state websites for online filing.
          Select your state and jurisdiction to see available forms and resources.
        </p>
        <a
          class="nav-link"
          aria-label="Frequently Asked Questions Link"
          @click="navigateToFaqCategory"
        >
          Frequently Asked Questions
          <arrow-right class="arrow-icon" />
        </a>
      </b-col>
    </b-row>
    <template v-if="hasCompanies">
      <b-row class="card-border">
        <b-col md="6" cols="12">
          <span class="drop-down-header">Company</span>
          <company-select
            class="mb-3"
            :companies="companies"
            @input="companySelected"
          />
        </b-col>
        <b-col md="6" cols="12">
          <span class="drop-down-header">Jurisdiction</span>
          <jurisdiction-select
            class="mb-3"
            :is-disable="disableJurisdictionSelect"
            @input="jurisdictionSelectHandler"
          />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col md="6" cols="12">
          <div class="information-box">
            <font-awesome-svg
              :type="'circle-info'"
              :fill="'#028EF2'"
              :width="16"
              :height="16"
            />
            <p>
              There are no businesses tied to this account and therefore no forms/resources for filing.
            </p>
          </div>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :class="{
            'btn-shadow mr-2': $mq !== 'sm',
            'add-business-outline': hasCompanies,
            'add-business-solid': !hasCompanies
          }"
          class="add-business-btn"
          aria-label="Add a Business to this Account"
          @click="newCompanyPage"
        >
          <feather-icon type="plus" />
          Add a Business to this Account
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { faqMixin } from '@/mixins/faqMixin'

export default {
  name: 'CompanySelection',
  components: {
    FeatherIcon: () => import('@/components/shared/FeatherIcon.vue'),
    ArrowRight: () => import('@images/ui/arrow-right-2FB2AE.svg'),
    FontAwesomeSvg: () => import('@/components/shared/FontAwesomeSvg.vue'),
    CompanySelect: () => import('@/components/CompanySelect'),
    JurisdictionSelect: () => import('@/components/JurisdictionSelect'),
    Masthead: () => import('@/components/shared/Masthead'),
  },
  mixins: [faqMixin],
  data() {
    return {
      selectedCompanyId: null,
      disableJurisdictionSelect: true,
      faqCategoryId: null,
      faqInteraction: {
        type: 'link',
        action: 'nav-from-page',
        categoryName: 'Forms Library',
        timestamp: Date.now(),
      },
    }
  },
  computed: {
    ...mapGetters('companies', ['companies', 'count']),
    hasCompanies() {
      return this.count || 0
    },
  },
  async mounted() {
    await this.$store.dispatch('companies/loadMore', false)

    if (this.faqInformation.length === 0) {
      await this.fetchFaqInformation()
    }
    this.faqCategoryId = this.faqInformation.find(item => item.name === "Forms Library")?.id
  },
  methods: {
    companySelected(companyId) {
      this.selectedCompanyId = companyId
      if(this.selectedCompanyId != null){
        this.disableJurisdictionSelect = false
      }
    },
    jurisdictionSelectHandler(jurisdictionId) {
      this.$router.push({
        name: 'jurisdictionFilings',
        params: {
          companyId: this.selectedCompanyId,
          jurisdictionId: jurisdictionId,
        },
      })
    },
    newCompanyPage() {
      this.$router.push({ name: 'createCompany', params: { origin: 'formsLibrary' } })
    },
    navigateToFaqCategory() {
      this.logFaqCategoryNavigation(this.faqCategoryId, this.faqInteraction)
    },
  },
}
</script>

<style lang="scss" scoped>
.container-max-width {
  max-width: 62rem;
}

::v-deep h2 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #4E4E52;
}

p {
  font-size: 1.0rem;
  font-weight: 400;
  color: #4E4E52;
}

.nav-link {
  padding-left: 0;
  font-size: 1.0rem;
  font-weight: 400;
  color: #009FBF !important;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.card-border {
  margin-top: 1.5rem;
  border: 0;
}

.drop-down-header {
  font-size: 1.0rem;
  font-weight: 400;
  color: #616165;
}

.information-box {
  width: 100%;
  padding: 0.75rem;
  background: #E3F3FF;
  color: #4E4E52;
  border: 1px solid #028EF2;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  text-align: left;

  p {
    margin: 0;
  }
}

::v-deep .multiselect {
  margin-top: 1.0rem !important;
}

::v-deep .add-business-btn {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.0rem;
  font-weight: 600;

  &.add-business-outline {
    background: white;
    color: #4E4E52;
    border-color: #CECED2;
    border-radius: 0.3125rem;

    &:hover {
      background: #2E3798;
      border-color: #2E3798;
      color: white;
    }
  }

  &.add-business-solid {
    background: #2E3798;
    color: white;
    border-color: #2E3798;
    border-radius: 0.3125rem;
  }
}

@media only screen and (min-width: 768px) {
  .add-business-btn {
    width: 48% !important;
  }
}

@media only screen and (max-width: 576px) {
  ::v-deep .toolbar-row-wrap {
    padding-top: 0 !important;

    .router-link {
      display: none;
    }
  }

  ::v-deep hr {
    display: none;
  }

  ::v-deep .margin-responsive {
    margin-bottom: 1.0rem !important;
  }

  p,
  .nav-link {
    font-size: 0.875rem;
  }

  .drop-down-header {
    padding-left: 0.5rem;
    font-size: 0.75rem;
  }

  ::v-deep .multiselect {
    margin-top: 0.5rem !important;
  }

  .card-border {
    margin-top: 0;
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    border: 1px solid #CECED2;
    border-radius: 0.3125rem;
  }

  .information-box {
    padding: 0.5rem;
    gap: 0.5rem;

    p {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}
</style>
