var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2 container-max-width" },
    [
      _c(
        "masthead",
        { attrs: { title: "Forms Library", "show-search": false } },
        [
          _c(
            "router-link",
            {
              staticClass: "router-link nav-link",
              attrs: { to: { name: "dashpanel" } },
            },
            [
              _c("fa-icon", {
                staticClass: "fa-xs",
                attrs: { icon: "chevron-left" },
              }),
              _vm._v("\n      Home\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "mb-4" }, [
              _vm._v(
                "\n        The Forms Library is a collection of resources to allow you to complete and submit a filing on your own.\n        Depending on which filing methods your state offers, these may include forms to fill out and submit or links to state websites for online filing.\n        Select your state and jurisdiction to see available forms and resources.\n      "
              ),
            ]),
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { "aria-label": "Frequently Asked Questions Link" },
                on: { click: _vm.navigateToFaqCategory },
              },
              [
                _vm._v("\n        Frequently Asked Questions\n        "),
                _c("arrow-right", { staticClass: "arrow-icon" }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.hasCompanies
        ? [
            _c(
              "b-row",
              { staticClass: "card-border" },
              [
                _c(
                  "b-col",
                  { attrs: { md: "6", cols: "12" } },
                  [
                    _c("span", { staticClass: "drop-down-header" }, [
                      _vm._v("Company"),
                    ]),
                    _c("company-select", {
                      staticClass: "mb-3",
                      attrs: { companies: _vm.companies },
                      on: { input: _vm.companySelected },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6", cols: "12" } },
                  [
                    _c("span", { staticClass: "drop-down-header" }, [
                      _vm._v("Jurisdiction"),
                    ]),
                    _c("jurisdiction-select", {
                      staticClass: "mb-3",
                      attrs: { "is-disable": _vm.disableJurisdictionSelect },
                      on: { input: _vm.jurisdictionSelectHandler },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { md: "6", cols: "12" } }, [
                  _c(
                    "div",
                    { staticClass: "information-box" },
                    [
                      _c("font-awesome-svg", {
                        attrs: {
                          type: "circle-info",
                          fill: "#028EF2",
                          width: 16,
                          height: 16,
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          "\n            There are no businesses tied to this account and therefore no forms/resources for filing.\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticClass: "add-business-btn",
                  class: {
                    "btn-shadow mr-2": _vm.$mq !== "sm",
                    "add-business-outline": _vm.hasCompanies,
                    "add-business-solid": !_vm.hasCompanies,
                  },
                  attrs: {
                    variant: "primary",
                    "aria-label": "Add a Business to this Account",
                  },
                  on: { click: _vm.newCompanyPage },
                },
                [
                  _c("feather-icon", { attrs: { type: "plus" } }),
                  _vm._v("\n        Add a Business to this Account\n      "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }